import React, { useEffect, useState, useContext } from 'react';
import firebase from 'firebase';
import { Box, Grommet } from 'grommet';
import Cards from './Cards'
import Graphs from './Graphs';
import {BoxProvider} from './Context'
import { trackPromise } from 'react-promise-tracker';
import {DataContext} from '../Context/data';


const Main = (props) => {

    
    const context = useContext(DataContext);
    
    console.log("DATA CONTEXT", context)

    const theme = {
        global: {
            colors: {
                "background-1": '#a9a9a9'
            }
        }
    }
    
    return (
        <BoxProvider>
            <Grommet  theme={theme}>
                <Box className='mainBody' >
                        <Cards 
                            size={props.size}
                            vixData={context.vixData} 
                            priceData={context.priceData} 
                            currentVix={context.currentVIX} 
                            vix5DaySMAData={context.vix5DaySMAData} 
                            targetPeg={context.targetPeg} 
                            currentMarketCap={context.currentMarketCap}
                            currentFDVMarketCap={context.currentFDVMarketCap}
                            currentSupply={context.currentSupply}
                            circulatingSupply={context.currentCirculatingSupply}
                            livePrice={context.livePrice}
                            TWAP={context.TWAP}
                            lastRebase={context.lastRebase}
                        />
                        <Graphs 
                            size={props.size}
                            marketCapData={context.marketCapData} 
                            marketCapFDVData={context.marketCapFDVData}
                            vixData={context.vixData}  
                            priceData={context.priceData} 
                            totalSupplyData={context.totalSupplyData}
                        />
                </Box>
            </Grommet>
        </BoxProvider>
    )
}

export default Main;


