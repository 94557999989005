import React, { useState } from 'react';
import { Box, Grommet, Text } from 'grommet';
import { Line } from '@nivo/line'; 
import { linearGradientDef } from '@nivo/core';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

const FiveDaySmaVixGraph = (props) => {

    const [value, setValue] = useState("all");

    const gridTheme = {
        global: {
            colors: {
                brand: '',
                'neutral-1': '#989898',
                'background-1': "#a9a9a9",
                'background-2': "#656565",
                'background-3': "#111111"
            }
        }
    }

    const data = props.vixData;
    let graphData;
    let desktopTicks;
    let mobileTicks;

    // function checking to see if the index exists in the data array
    const dataChecker = (number) => {
        if (data[data.length - number] === undefined) {
            return 0
        } else {
            return data.length - number
        }
    }

    const thirtyDaysOfData = data.slice(dataChecker(30), data.length);
    const sixtyDaysOfData = data.slice(dataChecker(60), data.length)
    const ninteyDaysOfData = data.slice(dataChecker(90), data.length)
    const oneYearOfData = data.slice(dataChecker(365), data.length)
    const allData = data;

    const handleChange = (val) => setValue(val);



    if (value === "30") {
        graphData = thirtyDaysOfData;
        desktopTicks = "1";
        mobileTicks = "2";
    } else if (value === "60") {
        graphData = sixtyDaysOfData;
        desktopTicks = "2";
        mobileTicks = "3";
    } else if (value === "90") {
        graphData = ninteyDaysOfData;
        desktopTicks = "7";
        mobileTicks = "3";
    } else if (value === "1") {
        graphData = oneYearOfData;
        desktopTicks = "30";
        mobileTicks = "4";
    } else if (value === "all") {
        graphData = allData;
        desktopTicks = "2";
        mobileTicks = "4";
    }

    const cboeVixData = [
        {
            id: '',
            color: "#2CF48B",
            data: graphData
        }
    ]


    const commonPropertiesSmall = {
        height: 200,
        width: 330,
        margin: { top: 20, right: 10, bottom: 60, left: 40 },
        animate: true,
        enableSlices: 'x',
    }
    const commonPropertiesLarge = {
        height: 320,
        width: 740,
        margin: { top: 20, right: 20, bottom: 60, left: 40 },
        animate: true,
        enableSlices: 'x',
    }

    const theme = {
        "background": "#2a2e35",
        "textColor": "#ffffff",
        "fontSize": 11,
        "axis": {
            "domain": {
                "line": {
                    "stroke": "#cfd0d3",
                    "strokeWidth": 1
                }
            },
            "ticks": {
                "line": {
                    "stroke": "#777777",
                    "strokeWidth": 1
                }
            }
        },
        "grid": {
            "line": {
                "stroke": "#cfd0d3",
                "strokeWidth": 1,
                "opacity": .1
            }
        }
    }

    const Tooltip = function ({ slice }) {
        return (
            <div
                style={{
                    background: "#09001b",
                    padding: "9px 12px",
                    borderRadius: "4px"
                }}
            >
                {slice.points.map(point => (
                    <div
                        key={point.id}
                        style={{
                            color: point.serieColor,
                            padding: "3px 0",

                        }}
                    >
                        <strong>{point.serieId}</strong> {point.data.yFormatted}
                    </div>
                ))}
            </div>
        );
    };

    const VixGraph = () => {

            if (props.size === 'small') {
                return (
                    <Line
                        {...commonPropertiesSmall}
                        colors='#2CF48B'
                        theme={theme}
                        curve="monotoneX"
                        data={cboeVixData}
                        xScale={{
                            type: 'time',
                            format: '%Y-%m-%d',
                            useUTC: false,
                            precision: 'day',
                        }}
                        xFormat="time:%Y-%m-%d"
                        yScale={{
                            type: 'linear',
                            min: 0,
                            max: 35
                        }}

                        axisLeft={{
                            legend: '',
                            legendOffset: -30,
                        }}
                        axisBottom={{
                            format: '%m/%d/%y',
                            tickValues: `every ${mobileTicks} days`,
                            tickRotation: -38,
                            legend: '',
                            legendOffset: 30,

                        }}
                        enablePoints={false}
                        enableArea={true}
                        areaOpacity={1}
                        defs={[
                            linearGradientDef('gradientA', [
                                { offset: 10, color: '#2CF48B', opacity: .5 },
                                { offset: 100, color: '#2CF48B', opacity: 0 },
                            ]),
                        ]}
                        fill={[{ match: '*', id: 'gradientA' }]}
                        sliceTooltip={({ slice }) => {
                            return <Tooltip slice={slice} />;
                        }}
                    />
                )
            } else {
                return (
                    <Line
                        {...commonPropertiesLarge}
                        colors='#2CF48B'
                        theme={theme}
                        curve="monotoneX"
                        data={cboeVixData}
                        xScale={{
                            type: 'time',
                            format: '%Y-%m-%d',
                            useUTC: false,
                            precision: 'day',
                        }}
                        xFormat="time:%Y-%m-%d"
                        yScale={{
                            type: 'linear',
                            min: 0,
                            max: 35
                        }}

                        axisLeft={{
                            legend: '',
                            legendOffset: -30,
                        }}
                        axisBottom={{
                            format: '%m/%d/%y',
                            tickValues: `every ${desktopTicks} days`,
                            tickRotation: -38,
                            legend: '',
                            legendOffset: 30,

                        }}
                        enablePoints={false}
                        enableArea={true}
                        areaOpacity={1}
                        defs={[
                            linearGradientDef('gradientA', [
                                { offset: 10, color: '#2CF48B', opacity: .5 },
                                { offset: 100, color: '#2CF48B', opacity: 0 },
                            ]),
                        ]}
                        fill={[{ match: '*', id: 'gradientA' }]}
                        sliceTooltip={({ slice }) => {
                            return <Tooltip slice={slice} />;
                        }}
                    />
                )
            }

            
        
    }

    return (
        <Grommet theme={gridTheme}>
            {(props.size === 'small') ? (
                <Box
                    background="#2a2e35"
                    pad='medium'
                    round='4px'
                    margin='xsmall'
                    className='graphBox'
                    height="250px"
                    width='350px'
                >
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Box>
                            <Text className='graphBox__text'>CBOE VIX</Text>
                        </Box>
                        <Box>
                            <ButtonGroup size="sm">
                                <Button variant="secondary" type="button" onClick={(e) => { handleChange('30') }}>30 Days</Button>
                                {/* <Button variant="secondary" type="button" onClick={(e) => {handleChange('60')}}>60 Days</Button>
                                    <Button variant="secondary" type="button" onClick={(e) => {handleChange('90')}}>90 Days</Button>
                                    <Button variant="secondary" type="button" onClick={(e) => {handleChange('1')}}>1 Year</Button> */}
                                <Button variant="secondary" type="button" onClick={(e) => { handleChange('all') }}>All</Button>
                            </ButtonGroup>
                        </Box>
                    </Box>
                    <VixGraph />
                </Box>

            ) : (

                    <Box
                        background="#2a2e35"
                        pad='medium'
                        round='4px'
                        margin='xsmall'
                        className='graphBox'
                        height="medium"
                        width='large'
                    >
                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '35px' }}>
                            <Box>
                                <Text className='graphBox__text'>CBOE VIX</Text>
                            </Box>
                            <Box>
                                <ButtonGroup size="sm">
                                    <Button variant="secondary" type="button" onClick={(e) => { handleChange('30') }}>30 Days</Button>
                                    {/* <Button variant="secondary" type="button" onClick={(e) => {handleChange('60')}}>60 Days</Button>
                                    <Button variant="secondary" type="button" onClick={(e) => {handleChange('90')}}>90 Days</Button>
                                    <Button variant="secondary" type="button" onClick={(e) => {handleChange('1')}}>1 Year</Button> */}
                                    <Button variant="secondary" type="button" onClick={(e) => { handleChange('all') }}>All</Button>
                                </ButtonGroup>
                            </Box>
                        </Box>
                        <VixGraph />
                    </Box>
                )
            }
        </Grommet>

    )
}

export default FiveDaySmaVixGraph;