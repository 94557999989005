import React, { useState } from 'react';
import {
    Box,
    Button,
    Collapsible,
    Heading,
    Grommet,
    Layer,
    Footer,
    ResponsiveContext,
    Text,
    Anchor,
    Main,
    Paragraph,
    Menu,
    Image,
    Accordion,
    AccordionPanel,
} from 'grommet';


import { FormClose, FormDown as FormDownIcon, FormUp as FormUpIcon } from 'grommet-icons';
// import { HashLink } from 'react-router-hash-link';
import { FaDiscord, FaTelegramPlane, FaTwitter, FaMediumM, FaRedditAlien, FaGithub } from 'react-icons/fa';
import difiPlusLogo from '../Assets/DFP-Logo_brand.png';
import benchmarkLogo from '../Assets/logo.png';
import closeHamburger from '../Assets/hamburger_Icons-03.png';
import'../css/Style.css';


const MobileMenu = (props) => {

    const [moreOpen, setMoreOpen] = useState(false)
    

    const toggleMore = () => {
        setMoreOpen(!moreOpen)
    }

    
    return (
        (!props.showSidebar) ? null : (
            <Grommet>
            <Layer full className="mobile-menu-modal middle-background" >
                <Box
                    tag='header'
                    direction='row'
                    align='center'
                    justify='between'
                    pad={{ left: 'medium', right: 'small', vertical: 'medium' }}
                    elevation='medium'
                    style={{ zIndex: '1', borderBottom: '1px solid #333333' }}
                    background='black'>
                    <a href="https://benchmarkprotocol.finance/">
                            <Image fill={false} className="nav-logo-mobile" src={benchmarkLogo} alt="the Benchmark Protocol Logo" />
                    </a>

                    <Button
                            icon={<img src={closeHamburger} class="hamburger-img" alt="an icon to close a hamburger menu" style={{ height: '50px', width: '50px' }} />}
                        onClick={props.closeSidebar}
                    />
                </Box>


                <Box
                    fill
                    background='black'
                    align='center'
                    justify='center'
                    className="mobile-menu-links-container"
                >

                    <Box className="mobile-menu-links-container-inner" >

                        <a className="menu-item-mobile" href="https://benchmarkprotocol.finance/" onClick={props.closeSidebar}>Home</a>
                        <a className="menu-item-mobile" href="https://benchmarkprotocol.finance/#impact" onClick={props.closeSidebar}>Impact</a>
                        <a className="menu-item-mobile" href="https://benchmarkprotocol.finance/why-benchmark" onClick={props.closeSidebar}>About</a>
                        <a className="menu-item-mobile" href="https://benchmarkprotocol.finance/why-benchmark#roadmap" onClick={props.closeSidebar}>Roadmap</a>
                        <a className="menu-item-mobile" href="https://benchmarkprotocol.finance/why-benchmark#team" onClick={props.closeSidebar}>Team</a>
                        <a className="menu-item-mobile" href="https://benchmarkprotocol.finance/faq" onClick={props.closeSidebar}>FAQ</a>
                        <a onClick={toggleMore} className="more-dropdown-mobile"><Text size='xlarge' color='white'>More </Text>{(moreOpen) ? <FormUpIcon color="#2CF48B" className="more-dropdown-mobile-arrow" /> : <FormDownIcon color="#2CF48B" className="more-dropdown-mobile-arrow" />}</a>

                        <Collapsible open={moreOpen}>

                            <Box className="mobile-nav-collapsible " >
                                <a className="menu-item-mobile" href="https://drive.google.com/file/d/1xRhdRLnGP9SQazZ9PHAkrnAeHR45wQtE/view?usp=sharing">White Paper</a>
                                <a className="menu-item-mobile" href="https://drive.google.com/file/d/10P-K0RuW8s4B6bspXHwywLc-l1zneat_/view?usp=sharing">Lite Paper</a>
                                <a className="menu-item-mobile" href="https://drive.google.com/file/d/1mrklXkLCEdWCm41SU9REesjP5UbFty_D/view?usp=sharing">Overview</a>
                                <a className="menu-item-mobile" href="https://benchmarkprotocol.medium.com/">Blog</a>
                                <a className="menu-item-mobile" href="https://benchmarkprotocol.finance/contact">Contact Us</a>
                                <a className="menu-item-mobile" href="https://angel.co/company/benchmark-protocol/jobs">Careers</a>
                                <br /><br />
                            </Box>
                        </Collapsible>
                    </Box>


                </Box>
                <Box direction="row" align='center' justify='between' className="mobile-nav-icons-container" background='black' pad={{'bottom': '10px'}} style={{borderTop: '1px solid #333333'}}>
                    <FaGithub color='red' size={40} className="foot-icon-mobile-nav" onClick={() => window.location.href ="https://github.com/benchmarkprotocol"} />
                    <FaTwitter size={40} className="foot-icon-mobile-nav" onClick={() => window.location.href = "https://mobile.twitter.com/__benchmark__"} />
                    <FaDiscord size={40} className="foot-icon-mobile-nav" onClick={() => window.location.href = "https://discord.gg/HcxAEaHG3X"} />
                    <FaTelegramPlane size={40} className="foot-icon-mobile-nav" onClick={() => window.location.href = "https://t.me/joinchat/Tt7sw00qqNnEWLIOzmYQ_w"} />
                    <FaRedditAlien size={40} className="foot-icon-mobile-nav" onClick={() => window.location.href = "https://www.reddit.com/r/BenchmarkProtocol/"} />
                    <FaMediumM size={40} className="foot-icon-mobile-nav" onClick={() => window.location.href = "https://medium.com/benchmarkprotocol"} />
                    <img src={difiPlusLogo} style={{ width: '150px' }} className="foot-icon-mobile-nav" onClick={() => window.location.href = "https://defipulse.com/"} />
                </Box>
            </Layer>
            </Grommet>
        )
    );
    
}

export default MobileMenu;
