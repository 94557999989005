import React, { useEffect, useState } from 'react';
import momentBusiness from 'moment-business-days';
//import moment from 'moment';
import Countdown from 'react-countdown';
const fedHolidays = require('@18f/us-federal-holidays');
var moment = require('moment-timezone');
const RebaseCountdown = (props) => {
    console.log("PROPS", props)
    return (
            props.lastRebase ?
                <Countdown date={new Date((props.lastRebase + 43200) * 1000)}>
                    
                </Countdown>
                : 
                <span>Loading...</span>
    )
}

export default RebaseCountdown;