import React, { useState } from 'react';
import { Box, Grommet, Text } from 'grommet';
import { ResponsivePie ,Pie } from '@nivo/pie'

const SdrPegGraph = (props) => {

    const [isShown, setIsShown] = useState(false);


/*
Chinese yuan
Euro
Japanese yen
U.K. pound
U.S. dollar

10.91%
32.04%
7.58%
8.36%
41.12%

*/
    const data = [
        {
            "id": "U.S. Dollar",
            "label": "U.S. Dollar",
            "value": 41.12,
            "color": "hsl(38, 70%, 50%)"
        },
        {
            "id": "Euro",
            "label": "Euro",
            "value": 32.04,
            "color": "hsl(303, 70%, 50%)"
        },
        {
            "id": "Chinese Yuan",
            "label": "Chinese Yuan",
            "value": 10.91,
            "color": "hsl(53, 70%, 50%)"
        },
        {
            "id": "Japanese Yen",
            "label": "Japanese Yen",
            "value": 7.58,
            "color": "hsl(43, 70%, 50%)"
        },
        {
            "id": "Pound Sterling",
            "label": "Pound Sterling",
            "value": 8.36,
            "color": "hsl(127, 70%, 50%)"
        }
    ]

    const gridTheme = {
        global: {
            colors: {
                brand: '',
                'neutral-1': '#989898',
                'background-1': "#a9a9a9",
                'background-2': "#656565",
                'background-3': "#111111"
            }
        }
    }


    const theme = {
        "background": "#2a2e35",
        "textColor": "#ffffff",
        "fontSize": 11,
        "axis": {
            "domain": {
                "line": {
                    "stroke": "#cfd0d3",
                    "strokeWidth": 1
                }
            },
            "ticks": {
                "line": {
                    "stroke": "#777777",
                    "strokeWidth": 1
                }
            }
        },
        "grid": {
            "line": {
                "stroke": "#cfd0d3",
                "strokeWidth": 1
            }
        },
        tooltip: {
            container: {
                background: '#333',
            },
        },
    }

    const Tooltip = function ({ slice }) {
        return (
            <div
                style={{
                    background: "#09001b",
                    padding: "9px 12px",
                }}
            >
                {slice.points.map(point => (
                    <div
                        key={point.id}
                        style={{
                            color: point.serieColor,
                            padding: "3px 0",
                        }}
                    >
                        <strong>{point.serieId}</strong> {point.data.yFormatted}
                    </div>
                ))}
            </div>
        );
    };

    const commonPropertiesSmall = {
        height: 200,
        width: 330,
        margin: { top: 20, right: 20, bottom: 20, left: 40 },
        animate: true,
        enableSlices: 'x',
    }
    const commonPropertiesLarge = {
        height: 320,
        width: 740,
        margin: { top: 20, right: 20, bottom: 60, left: 40 },
        animate: true,
        enableSlices: 'x',
    }

    const MyResponsivePie = () => {
        if (props.size === 'small') {
            return (
                <ResponsivePie
                    theme={theme}
                    data={data}
                    {...commonPropertiesSmall}
                    innerRadius={0.3}
                    padAngle={0.7}
                    cornerRadius={3}
                    colors={{ scheme: 'nivo' }}
                    borderWidth={1}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextColor="white"
                    radialLabelsLinkColor={{ from: 'color' }}
                    radialLabelsLinkOffset={-5}
                    radialLabelsLinkHorizontalLength={5}
                    sliceLabelsSkipAngle={10}
                    sliceLabelsTextColor="#333333"
                />
            )
        } else {
            return (
                <ResponsivePie
                    theme={theme}
                    data={data}
                    {...commonPropertiesLarge}
                    innerRadius={0.4}
                    padAngle={0.7}
                    cornerRadius={3}
                    colors={{ scheme: 'nivo' }}
                    borderWidth={1}
                    borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                    radialLabelsSkipAngle={10}
                    radialLabelsTextColor="white"
                    radialLabelsLinkColor={{ from: 'color' }}
                    sliceLabelsSkipAngle={10}
                    sliceLabelsTextColor="#333333"
                />
            )
        } 
    }




    return (
        <Grommet theme={gridTheme}>
            {(props.size === 'small') ? (
                <Box
                    background="#2a2e35"
                    pad='medium'
                    round='4px'
                    margin='xsmall'
                    className='graphBox'
                    height="250px"
                    width='350px'
                >
                    <Text size='small' className='graphBox__text'>SDR Basket</Text>
                    <MyResponsivePie />
                </Box>

            ) : (

                    <Box
                        background="#2a2e35"
                        pad='medium'
                        round='4px'
                        margin='xsmall'
                        className='graphBox'
                        height="medium"
                        width='large'
                    >
                        <Text className='graphBox__text'>SDR Basket</Text>
                        <MyResponsivePie />
                    </Box>
                )
            }
        </Grommet>

    )
}

export default SdrPegGraph;