import React, { useEffect } from 'react';
import { Box, Grommet } from 'grommet';
import MarketCapGraph from './graphs/MarketCap';
import SdrPegGraph from './graphs/SdrPeg';
import CirculationSupplyGraph from './graphs/CirculationSupply';
import FiveDaySmaVixGraph from './graphs/5DaySmaVix';
import PriceGraph from './graphs/Price';
import {BoxContext }from './Context';



const Graphs = (props) => {
    
    // Importing State


    const gridTheme = {
        global: {
            colors: {
                brand: '',
                'neutral-1': '#989898',
                'background-1': "black",
                'background-2': "#656565",
                'background-3': "#111111"
            }
        }
    }

    useEffect(() => {
        // console.log("show charts", props.chartsToShow)
    })

    return (
        <BoxContext.Consumer>
            {context => (
                <Grommet theme={gridTheme} >
                    <Box background=''
                        direction='row'
                        pad='medium'
                        margin='medium'
                        style={{
                            borderRadius: '10px',
                            flexWrap: 'wrap',
                            minHeight: '400px',
                            justifyContent:'center',
                        }}
                    >
                        {context.boxes.map((value, i) => {
                            console.log("MAP INCR", i)
                            if (value==="price"){
                                 return <PriceGraph priceData={props.priceData} key={i} size={props.size} />
                             } else if (value==="sdr"){
                                return <SdrPegGraph key={i} size={props.size} /> 
                             } else if (value==="marketcap"){
                                return <MarketCapGraph marketCapData={props.marketCapData} marketCapFDVData={props.marketCapFDVData} key={i} size={props.size} /> 
                             } else if (value==="supply"){
                                return <CirculationSupplyGraph totalSupplyData={props.totalSupplyData} key={i} size={props.size} /> 
                             } else if (value==="vix"){
                                return <FiveDaySmaVixGraph vixData={props.vixData} key={i} size={props.size} /> 
                             }
                        })}



                    </Box>
                </Grommet>

            )}
        </BoxContext.Consumer>
    )
}

export default Graphs;