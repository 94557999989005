import React, { useEffect, useState } from 'react';
import { Box, Grommet, Text } from 'grommet';
import { Line } from '@nivo/line'; 
import { linearGradientDef } from '@nivo/core';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Download as DownloadIcon,  } from 'grommet-icons';
const MarketCapGraph = (props) => {

    const [value, setValue] = useState("all");
    const [dataType, setDataType] = useState(true)
    const [FDVType, setFDVType] = useState(true)

    useEffect(() => {
        console.log("FDV", props.marketCapFDVData)
        // console.log(dataType)
    }, [dataType])


    let data = (props.marketCapData)
    if (FDVType){
        data = props.marketCapFDVData
    }

    let graphData;
    let desktopTicks;
    let mobileTicks;

    // function checking to see if the index exists in the data array
    const dataChecker = (number) => {
        if (data[data.length - number] === undefined) {
            return 0
        } else {
            return data.length - number
        }
    }

    const thirtyDaysOfData = data.slice(dataChecker(30), data.length);
    const sixtyDaysOfData = data.slice(dataChecker(60), data.length)
    const ninteyDaysOfData = data.slice(dataChecker(90), data.length)
    const oneYearOfData = data.slice(dataChecker(365), data.length)
    const allData = data;

    const handleChange = (val) => setValue(val);

    const updateDataType = (val) => setDataType(val);

    const updateFDVType = (val) => setFDVType(val);

    const convertAbsoluteToPercentageChange = (data) => {
        let newData = [];
        for (var i = 1; i < data.length; i++) {
            newData.push({ x: data[i].x, y: ((data[i].y - data[i - 1].y) / data[i - 1].y * 100) });
        }
        return newData;
    }





    if (dataType) {
        if (value === "30") {
            graphData = thirtyDaysOfData;
            desktopTicks = "1";
            mobileTicks = "2";
        } else if (value === "60") {
            graphData = sixtyDaysOfData;
            desktopTicks = "4";
            mobileTicks = "4";
        } else if (value === "90") {
            graphData = ninteyDaysOfData;
            desktopTicks = "5";
            mobileTicks = "7";
        } else if (value === "1") {
            graphData = oneYearOfData;
            desktopTicks = "30";
            mobileTicks = "4";
        } else if (value === "all") {
            graphData = allData;
            desktopTicks = "8";
            mobileTicks = "11";
        }
    } else {
        if (value === "30") {
            graphData = convertAbsoluteToPercentageChange(thirtyDaysOfData);
            desktopTicks = "1";
            mobileTicks = "2";
        } else if (value === "60") {
            graphData = convertAbsoluteToPercentageChange(sixtyDaysOfData);
            desktopTicks = "4";
            mobileTicks = "4";
        } else if (value === "90") {
            graphData = convertAbsoluteToPercentageChange(ninteyDaysOfData);
            desktopTicks = "5";
            mobileTicks = "7";
        } else if (value === "1") {
            graphData = convertAbsoluteToPercentageChange(oneYearOfData);
            desktopTicks = "30";
            mobileTicks = "4";
        } else if (value === "all") {
            graphData = convertAbsoluteToPercentageChange(allData);
            desktopTicks = "8";
            mobileTicks = "11";
        }
    }



    const gridTheme = {
        global: {
            colors: {
                brand: '',
                'neutral-1': '#989898',
                'background-1': "#a9a9a9",
                'background-2': "#656565",
                'background-3': "#111111"
            }
        }
    }

    const marketCapData = [
        {
            id: '',
            color: "#aaaaaa",
            data: graphData
        }
    ]
    
    const theme = {
        "background": "#2a2e35",
        "textColor": "#ffffff",
        "fontSize": 11,
        "axis": {
            "domain": {
                "line": {
                    "stroke": "#cfd0d3",
                    "strokeWidth": 1
                }
            },
            "ticks": {
                "line": {
                    "stroke": "#777777",
                    "strokeWidth": 1
                }
            }
        },
        "grid": {
            "line": {
                "stroke": "#cfd0d3",
                "strokeWidth": 1,
                "opacity": .1
            }
        }
    }

    const Tooltip = function ({ slice }) {
        // console.log(slice)
        if (dataType) {
            return (
                <div
                    style={{
                        background: "#09001b",
                        padding: "9px 12px",
                        borderRadius: "4px"
                    }}
                >
                    {slice.points.map(point => (
                        <div
                            key={point.id}
                            style={{
                                color: point.serieColor,
                                padding: "3px 0",
                            }}
                        >
                            <strong>{point.serieId}</strong> ${point.data.y.toFixed(2)}m

                        </div>
                    ))}
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        background: "#09001b",
                        padding: "9px 12px",
                        borderRadius: "4px"
                    }}
                >
                    <div
                        style={{
                            color: slice.serieColor,
                            padding: "3px 0",
                        }}
                    >
                        {slice.points.map(point => (
                            <div
                                key={point.id}
                                style={{
                                    color: point.serieColor,
                                    padding: "3px 0",
                                }}
                            >
                                <strong>{point.serieId}</strong> {point.data.y.toFixed(2)}%
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
    };


    const commonPropertiesSmall = {
        height: 200,
        width: 330,
        margin: { top: 20, right: 10, bottom: 60, left: 50 },
        animate: true,
        enableSlices: 'x',
    }
    const commonPropertiesLarge = {
        height: 320,
        width: 740,
        margin: { top: 20, right: 20, bottom: 60, left: 50 },
        animate: true,
        enableSlices: 'x',
    }

    const MarketCapGraph = () => {
        if (props.size === 'small') {
            return (
                <Line
                    {...commonPropertiesSmall}
                    theme={theme}
                    colors='#2CF48B'
                    curve="monotoneX"
                    data={marketCapData}
                    xScale={{
                        type: 'time',
                        format: '%Y-%m-%d',
                        useUTC: false,
                        precision: 'day',
                    }}
                    xFormat="time:%Y-%m-%d"
                    yScale={{
                        type: 'linear',
                        min:"auto"
                    }}
                    yFormat=" ^($01,.2~f"

                    axisLeft={{
                        legend: '',
                        legendOffset: -30,
                        format: (value) =>
                            (dataType) ? `$${Number(value)}m` : `${Number(value)}%`
                    }}
                    axisBottom={{
                        format: '%m/%d/%y',
                        tickValues: `every ${mobileTicks} days`,
                        tickRotation: -38,
                        legend: '',
                        legendOffset: 30,

                    }}
                    enablePoints={false}
                    enableArea={false}

                    defs={[
                        linearGradientDef('gradientA', [
                            { offset: 0, color: '#2CF48B', opacity: 1 },
                            { offset: 70, color: '#2CF48B', opacity: 0 },
                        ]),
                    ]}
                    fill={[{ match: '*', id: 'gradientA' }]}
                    sliceTooltip={({ slice }) => {
                        return <Tooltip slice={slice} />;
                    }}
                />
            )
        } else {
            return (
                <Line
                    {...commonPropertiesLarge}
                    theme={theme}
                    colors='#2CF48B'
                    curve="monotoneX"
                    data={marketCapData}
                    xScale={{
                        type: 'time',
                        format: '%Y-%m-%d',
                        useUTC: false,
                        precision: 'day',
                    }}
                    xFormat="time:%Y-%m-%d"
                    yScale={{
                        type: 'linear',
                        min:"auto"
                    }}
                    yFormat=" ^($01,.2~f"

                    axisLeft={{
                        legend: '',
                        legendOffset: -30,
                        format: (value) =>
                            (dataType) ? `$${Number(value)}m` : `${Number(value)}%`
                    }}
                    axisBottom={{
                        format: '%m/%d/%y',
                        tickValues: `every ${desktopTicks} days`,
                        tickRotation: -38,
                        legend: '',
                        legendOffset: 30,

                    }}
                    enablePoints={false}
                    enableArea={false}

                    defs={[
                        linearGradientDef('gradientA', [
                            { offset: 0, color: '#2CF48B', opacity: 1 },
                            { offset: 70, color: '#2CF48B', opacity: 0 },
                        ]),
                    ]}
                    fill={[{ match: '*', id: 'gradientA' }]}
                    sliceTooltip={({ slice }) => {
                        return <Tooltip slice={slice} />;
                    }}
                />
            )
        }

            
        
    }

    useEffect(() => {
        // props.MarketCapGraph
    })


    return (
        <Grommet theme={gridTheme}>
            {(props.size === 'small') ? (
                <Box
                    background="#2a2e35"
                    pad='medium'
                    round='4px'
                    margin='xsmall'
                    className='graphBox'
                    width='350px'
                >
                    <Box style={{ display: 'block', marginBottom: '5px' }}>
                        <Box>
                            <Text size='small' className='graphBox__text'>Market Cap</Text>
                        </Box>
                        <br/>
                        <Box className='d-flex flex-row' style={{ height: '28px' }}>

                            <ButtonGroup size="sm" className='mr-2'>
                                <Button className="active" variant="secondary" type="button" onClick={() => { updateFDVType(true) }}>Fully Diluted</Button>
                                <Button variant="secondary" type="button" onClick={() => { updateFDVType(false) }}>Circulating</Button>
                            </ButtonGroup>

                            <ButtonGroup size="sm" className='mr-2'>
                                <Button variant="secondary" type="button" onClick={() => { if (FDVType){ window.open('https://storage.googleapis.com/dashboard-backend-4835f.appspot.com/marketfullydiluted.csv'); } else { window.open('https://storage.googleapis.com/dashboard-backend-4835f.appspot.com/market.csv'); } }}>CSV <DownloadIcon size='small'/></Button>
                            </ButtonGroup>
                        </Box>
                        <br/>
                        <Box className='d-flex flex-row' style={{ height: '28px' }}>
                            
                            <ButtonGroup size="sm" className='mr-2'>
                                <Button variant="secondary" type="button" onClick={() => { updateDataType(true) }}>ABS</Button>
                                <Button variant="secondary" type="button" onClick={() => { updateDataType(false) }}>%</Button>
                            </ButtonGroup>
                            <br/>
                            <ButtonGroup size="sm">
                                <Button variant="secondary" type="button" style={{fontSize: '13px'}} onClick={(e) => { handleChange('30') }}>30 Days</Button>
                                <Button variant="secondary" type="button" style={{ fontSize: '13px' }} onClick={(e) => {handleChange('60')}}>60 Days</Button>
                                <Button variant="secondary" type="button" style={{ fontSize: '13px' }} onClick={(e) => {handleChange('90')}}>90 Days</Button>
                                {/* <Button variant="secondary" type="button" onClick={(e) => {handleChange('1')}}>1 Year</Button> */}
                                <Button variant="secondary" type="button" style={{ fontSize: '13px' }} onClick={(e) => { handleChange('all') }}>All</Button>
                            </ButtonGroup>

                        </Box>
                    </Box>
                    <MarketCapGraph />
                </Box>

            ) : (

                    <Box
                        background="#2a2e35"
                        pad='medium'
                        round='4px'
                        margin='xsmall'
                        className='graphBox'
                        height="medium"
                        width='large'
                    >
                        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '35px' }}>
                            <Box>
                                <Text className='graphBox__text'>Market Cap</Text>
                            </Box>
                            <Box className='d-flex flex-row' style={{ height: '30px' }}>

                                <ButtonGroup size="sm" className='mr-2'>
                                    <Button variant="secondary" type="button" onClick={() => { updateFDVType(true) }}>Fully Diluted</Button>
                                    <Button variant="secondary" type="button" onClick={() => { updateFDVType(false) }}>Circulating</Button>
                                </ButtonGroup>

                                <ButtonGroup size="sm" className='mr-2'>
                                    <Button variant="secondary" type="button" onClick={() => { if (FDVType){ window.open('https://storage.googleapis.com/dashboard-backend-4835f.appspot.com/marketfullydiluted.csv'); } else { window.open('https://storage.googleapis.com/dashboard-backend-4835f.appspot.com/market.csv'); } }}>CSV <DownloadIcon size='small'/></Button>
                                </ButtonGroup>

                                <ButtonGroup size="sm" className='mr-2'>
                                    <Button variant="secondary" type="button" onClick={() => { updateDataType(true) }}>ABS</Button>
                                    <Button variant="secondary" type="button" onClick={() => { updateDataType(false) }}>%</Button>
                                </ButtonGroup>

                                <ButtonGroup className='btnGroup' size="sm" >
                                    <Button variant="secondary" className='btnGroup__30' type="button" onClick={(e) => { handleChange('30') }}>30 Days</Button>
                                    <Button variant="secondary" type="button" onClick={(e) => {handleChange('60')}}>60 Days</Button>
                                    <Button variant="secondary" type="button" onClick={(e) => {handleChange('90')}}>90 Days</Button>
                                        {/* <Button variant="secondary" type="button" onClick={(e) => {handleChange('1')}}>1 Year</Button> */}
                                    <Button variant="secondary" type="button" onClick={(e) => { handleChange('all') }}>All</Button>
                                </ButtonGroup>
                            </Box>
                        </Box>
                        <MarketCapGraph />
                    </Box>
                )
            }
        </Grommet>

    )
}

export default MarketCapGraph;