import React, { useEffect, useState} from 'react';
import { Box, Button, Grommet } from 'grommet';
import {BoxContext }from './Context'

const CardButton = (props) => {

    const showButton = {
        button: {
            default: {
                background: { color: '#2CF48B'},
                border: {
                    radius: '4px'
                }
            }
        },
    };

    const hideButton = {
        button: {
            default: {
                background: { color: '#656565'},
                border: {
                    radius: '4px',
                    color: '#656565'
                }
            }
        }
    }


    return (
            <BoxContext.Consumer>
            {context => (
                <Grommet theme={(context.boxes && context.boxes.indexOf(props.graphid) > -1) ? hideButton : showButton}>
                    <Box width='80px' flex justify='center' align='center' >
                        <Button  label= {(context.boxes && context.boxes.indexOf(props.graphid) > -1) ? 'Hide' : 'Show'} onClick={() => {context.toggle(props.graphid)}}  />
                    </Box>
                </Grommet> 
                )}
            </BoxContext.Consumer>

    )
}

export default CardButton;