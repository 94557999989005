import React, { useState } from 'react';
import { Box, Grommet, ResponsiveContext } from 'grommet';
import Main from './Components/Main';
import '../src/App.css';
import Nav from './Components/Nav';
import Footer from './Components/Footer';
import MobileMenu from './Components/MobileMenu';
import Context from './Context/data';

function App() {
  const [showSidebar, setShowSidebar] = useState(false)
  console.log(showSidebar)

  const closeSidebar = () => {
    setShowSidebar(false)
  }

  const openSidebar = () => {
    setShowSidebar(true)
  }

  const theme = { 
    global: {
      colors: {
        // 'background': 'neutral-1'
      }
    }
  }

  return (
    <Context>
    <Grommet theme={theme}pad='medium' >
      <ResponsiveContext.Consumer>
        {size => (
          <Box >
            <Nav size={size} showSidebar={showSidebar} openSidebar={openSidebar} closeSidebar={closeSidebar} />
            <Box direction="column" justify="between" flex width="100%" height={{ "min": "calc(100% - 72px)" }} overflow={{ horizontal: 'hidden' }} >
              <MobileMenu showSidebar={showSidebar} closeSidebar={closeSidebar}/>
              <Main size={size} />
              <Footer size={size} />
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
    </Context>
  );
}

export default App;
