import React, { Component } from 'react';
import {
  Box,
  Button,
  Collapsible,
  Heading,
  Grommet,
  Layer,
  Footer,
  ResponsiveContext,
  Text,
  Anchor,
  Main,
  Paragraph,
  Menu,
	Form,
  FormField,
  TextInput,
  Image,
} from 'grommet';
import { Menu as MenuIcon,  } from 'grommet-icons';
import {FaDiscord, FaTelegramPlane, FaTwitter, FaMediumM, FaRedditAlien, FaGithub} from 'react-icons/fa';
import DifiPlusLogo from '../Assets/DFP-Logo_white.png';
import benchmarkLogo from '../Assets/logo.png';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import '../css/Style.css';

// import "bootstrap/dist/css/bootstrap.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";



export default class customFooter extends React.PureComponent {

	state = {

	}

	
	
	
	render() {

		const customTheme = {
			global: {
				
			},
			button: {
				border: {
					radius: '4px',
					color: '#2CF48B',
					background: {
						color: '#2CF48B'
					},
					extend: `'background: '#2CF48B';`
				},
				padding: {
					vertical: '6px',
					horizontal: '24px',
				},
				primary: {
					color: '#2CF48B',
					active: {
						border: {
							color: 'red',
						},
						extend: `background: #2CF48B;`,
					},
					extend: `background: #2CF48B;`,
				},

				extend: props => {
					let extraStyles = '';
					if (props.primary) {
						extraStyles = ``;
					}
					return `
			  font-size: 18px;
			  font-weight: bold;
			  ${extraStyles}
			`;
				},
			},
		};
		
		const renderGithubTooltip = props => (
			<Tooltip {...props}>Github</Tooltip>
		);
		const renderTwitterTooltip = props => (
			<Tooltip {...props}>Twitter</Tooltip>
		);
		const renderTelegramTooltip = props => (
			<Tooltip {...props}>Telegram</Tooltip>
		);
		const renderDiscordTooltip = props => (
			<Tooltip {...props}>Discord</Tooltip>
		);
		const renderMediumTooltip = props => (
			<Tooltip {...props}>Medium</Tooltip>
		);
		const renderDifiPulseTooltip = props => (
			<Tooltip {...props}>DeFi Pulse</Tooltip>
		);


		console.log("rendering footer")
		return (
			(this.props.size !== 'small' && this.props.size !== 'medsmall') ? (
				<Box className="bottom-background">
				<Footer pad="none" id='footer-desktop'  direction="column"  >
                  
				  <MailchimpSubscribe
				    url={"https://finance.us2.list-manage.com/subscribe/post?u=959e9c16228d34a337a704e71&amp;id=4ece8dd992"}
				    render={({ subscribe, status, message }) => (

	                  <Main pad="large" width="100%" className="inner-container" alignSelf="center">
						<a href="https://benchmarkprotocol.finance/" className="footer-logo" >
							<Image fill={false} className="footer-logo" alignSelf="start" src={benchmarkLogo} alt="the Benchmark Protocol logo"/>	
						</a>
	                    
	                    <Main direction="row" justify="between">
	                      <Main>



				        <Form onSubmit={({ value }) => { subscribe({EMAIL: value.Email}); console.log(value); }} >
				          <Main direction="row">
				            <FormField name="Email" htmlfor="footer-email-input" >
				              <TextInput  id="footer-email-input" className="footer-email-input-desktop" name="Email" placeholder="Email Address" size="large" pad="small" style={{borderBottom: '1px solid grey', borderRadius: '0px'}}/>
				            </FormField>
				            <Box direction="row" pad="small" gap="medium">
								<Grommet theme={customTheme} >
									<Button 
										type="submit"  
										label="Sign Up" 
										primary
									/>
								</Grommet>
				            </Box>
				          </Main>
				        </Form>


	                      </Main>
	                      <Main  className="foot-icon-container" direction="row" justify="end" margin={{"top":"10px"}}>
							<OverlayTrigger placement="top" overlay={renderGithubTooltip}>		
								<FaGithub size={30} className="foot-icon" onClick={() => window.location.href = "https://github.com/benchmarkprotocol"} data-toggle="tooltip" data-placement="top" title="Tooltip on top"/>
							</OverlayTrigger>
							<OverlayTrigger placement="top" overlay={renderTwitterTooltip}>	
								<FaTwitter size={30} className="foot-icon" onClick={() => window.location.href ="https://twitter.com/benchmark_defi?s=21"}/>
							</OverlayTrigger>
							<OverlayTrigger placement="top" overlay={renderDiscordTooltip}>		
								<FaDiscord size={30} className="foot-icon" onClick={() => window.location.href ="https://discord.gg/HcxAEaHG3X"}/>
							</OverlayTrigger>
							<OverlayTrigger placement="top" overlay={renderTelegramTooltip}>		
								<FaTelegramPlane size={30} className="foot-icon" onClick={()=> window.location.href="https://t.me/joinchat/Tt7sw00qqNnEWLIOzmYQ_w"}/>
							</OverlayTrigger>
								{/*<FaRedditAlien size={30} className="foot-icon" onClick={()=> window.location.href="https://example.com"}/>*/}
							<OverlayTrigger placement="top" overlay={renderMediumTooltip}>		
								<FaMediumM size={30} className="foot-icon" onClick={() => window.location.href ="https://medium.com/benchmarkprotocol"}/>
							</OverlayTrigger>
							<OverlayTrigger placement='top' overlay={renderDifiPulseTooltip}>
								<img src={DifiPlusLogo} style={{ width: '120px' }} className='foot-icon' onClick={() => window.location.href = "https://defipulse.com/"} />
							</OverlayTrigger>
						  </Main>

	                    </Main>
	                    <Main direction="row" margin={{"bottom":"17px", "top":"17px"}} pad={{"bottom":"17px", "top":"17px"}} justify="between">
	                      


	                      {(status === "sending") ? (
	                      		<Text size="small" >Sending...</Text>

	                      	) :  (status === "error") ? (
	                      		<Text size="small" style={{ color: "red" }}>Please enter a valid email.</Text>
	                      	) : (status === "success") ? (
	                      		<Text size="small" color="brand">Subscribed!</Text>
	                      	) : (
	                      		<Text size="small" >Subscribe to our newsletter for updates in the Benchmark ecosystem</Text>
	                      	)


	                  	  }
	                      <Box direction="column">
	                      	<Text size="small" alignSelf="end">© Blockchain United LTD 2020. All rights reserved.</Text>
	                      	<Box direction="row" justify="end">
								<a href="https://benchmarkprotocol.finance/contact" className="footer-link-text" >Contact Us</a>
	                      		<a href="https://angel.co/company/benchmark-protocol/jobs" className="footer-link-text">Careers</a>
	                      		<a href="https://benchmarkprotocol.finance/terms" className="footer-link-text" target="_blank">Terms</a>
	                      		<a href="https://benchmarkprotocol.finance/privacy" className="footer-link-text" target="_blank">Privacy</a>
	                      	</Box>
	                      </Box>
	                    </Main>
	                  </Main>

                    )}
  				/>

                </Footer>
				</Box>
              ) 
              : 
              (
                <Footer pad="none" direction="column" className='bottom-background-small' >


				  <MailchimpSubscribe
				    url={"https://finance.us2.list-manage.com/subscribe/post?u=959e9c16228d34a337a704e71&amp;id=4ece8dd992"}
				    render={({ subscribe, status, message }) => (

	                  <Main pad="large" width="100%" className="inner-container" alignSelf="center" >
							<a href="https://benchmarkprotocol.finance/" className="footer-logo-mobile" >
								<Image fill={false} className="footer-logo-mobile" alignSelf="start" src={benchmarkLogo} alt="the Benchmark Protocol logo" />
							</a>
	                    

							<Box flex direction="row" className="foot-icon-container" >
								<FaGithub size={30} className="foot-icon" onClick={() => window.location.href = "https://github.com/benchmarkprotocol"} />
								<FaTwitter size={30} className="foot-icon" onClick={() => window.location.href ="https://twitter.com/benchmark_defi?s=21"}/>
								<FaDiscord size={30} className="foot-icon" onClick={() => window.location.href ="https://discord.gg/HcxAEaHG3X"}/>
								<FaTelegramPlane size={30} className="foot-icon" onClick={()=> window.location.href="https://t.me/joinchat/Tt7sw00qqNnEWLIOzmYQ_w"}/>
								<FaRedditAlien size={30} className="foot-icon" onClick={() => window.location.href = "https://www.reddit.com/r/BenchmarkProtocol/"} />
								<FaMediumM size={30} className="foot-icon" onClick={() => window.location.href ="https://medium.com/benchmarkprotocol"}/>
								<img src={DifiPlusLogo} style={{ width: '120px' }} className='foot-icon' onClick={() => window.location.href = "https://defipulse.com/"} />
							</Box>


							<Main direction="column" margin={{ top: "10px" }} >
	                      <Main>
	                        <Form onSubmit={({ value }) => { subscribe({EMAIL: value.Email}); console.log(value); }}>
	                          <Main direction="column">
	                            <FormField name="Email" htmlfor="footer-email-input-mobile">
	                              <TextInput id="footer-email-input-mobile" name="Email" placeholder="Email Address" size="large" pad="small"/>
	                            </FormField>
	                            <Box direction="row" pad="small" gap="medium">
	                              <Button type="submit" primary label="Sign Up" />
	                            </Box>
	                          </Main>
	                        </Form>
	                      </Main>


	                    </Main>
	                    <Main direction="column" margin={{"bottom":"17px", "top":"17px"}} pad={{"bottom":"17px", "top":"17px"}} justify="between">
	                      {(status === "sending") ? (
	                      		<Text size="small" >Sending...</Text>
	                      	) :  (status === "error") ? (
	                      		<Text size="small" style={{ color: "red" }}>Please enter a valid email.</Text>
	                      	) : (status === "success") ? (
	                      		<Text size="small" color="brand">Subscribed!</Text>
	                      	) : (
	                      		<Text size="small" >Subscribe to our newsletter for updates in the Benchmark ecosystem</Text>
	                      	)
	                  	  }
	                      <Text size="small" margin={{top:"10px"}}>© Blockchain United LTD 2020. All rights reserved.</Text>
	                      <Box direction="row" justify="start"  margin={{top:"10px"}}>
	                      		<a href="https://benchmarkprotocol.finance/contact" className="footer-link-text" target="_blank">Contact Us</a>
								<a href="https://angel.co/company/benchmark-protocol/jobs" className="footer-link-text" target="_blank">Careers</a>
	                      		<a href="https://benchmarkprotocol.finance/terms" className="footer-link-text" target="_blank">Terms</a>
	                      		<a href="https://benchmarkprotocol.finance/privacy" className="footer-link-text" target="_blank">Privacy</a>
	                      	</Box>
	                    </Main>
	                  </Main>
                    )}
  				/>
                </Footer>
              )
        );
    }
}
