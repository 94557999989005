import React, { createContext, useContext, useState } from 'react';

export const BoxContext = createContext()

export class BoxProvider extends React.Component {
    state = {
        boxes:[]
    };

    render() {
        return (
            <BoxContext.Provider
                value={{
                    boxes: this.state.boxes,
                    toggle: chartID => {

				        if (this.state.boxes.indexOf(chartID) > -1){
				            let newCharts = this.state.boxes
				            newCharts.splice(this.state.boxes.indexOf(chartID), 1)
				            console.log("NEw charts", newCharts)
	                        this.setState({
	                            boxes:newCharts
	                        });
				        } else {
				            let newCharts = this.state.boxes
				            newCharts.unshift(chartID)
				            console.log("NEw charts", newCharts)
	                        this.setState({
	                            boxes:newCharts
	                        });
				        }


                    }
                }}
            >
                {this.props.children}
            </BoxContext.Provider>
        );
    }
}