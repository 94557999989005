import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardBody, Grid, Grommet, Text, Tip } from 'grommet';
import { IoMdInformationCircle } from 'react-icons/io';
import { 
BoxContext
} from './Context';
import CardButton from './Button';
import RebaseCountdown from './RebaseCountdown';
import Loader from 'react-loader-spinner';

const Cards = (props) => {

    const data = [
        {
            color: "#2a2e35",
            title: 'Rebalance Countdown',
            subTitle: null,
            button: ' ',
            message: props.lastRebase ? <RebaseCountdown lastRebase={props.lastRebase}/> : <RebaseCountdown/> ,
            tip: 'Estimated time remaining until the next active rebalance of the MARK Token. Rebalances occur every 12 hours with the help of Chainlink Keepers. If the MARK token price diverges from the target price, the protocol will rebalance to expand or contract supply.',
            toggle: ''

        },
        // {
        //     color: "##2a2e35",
        //     title: 'Mark Oracle Rate',
        //     subTitle: null,
        //     button: <CardButton state={box2State} boxToggle={updateBox2}/>,
        //     message: '1,000',
        //     tip: 'The latest weighted average price of the MARK Token during Rebalance. Essentially, it is the current price of the MARK Token on the cryptocurrency market.',
        //     toggle: ''
        // },
        /*{
            color: "##2a2e35",
            title: 'Mark Oracle Rate',
            subTitle: null,
            button: ' ',
            message: (props.TWAP === 0) ? <Loader
                type="Area"
                color="#2CF48B"
                height={50}
                width={80}
            /> 
                : 
            `$${props.TWAP}`,
            tip: 'The MARK Oracle Rate is a time-weighted average price, pulled hourly over a 24 hour period in order to calculate supply rebalancing.',
            toggle: ''
        },*/

        {
            color: "##2a2e35",
            title: 'Mark Price',
            subTitle: null,
            
            button: <CardButton graphid={"price"} />,
            message: (props.livePrice === 0) ? <Loader
                type="Area"
                color="#2CF48B"
                height={50}
                width={80}
            /> 
                :
            `$${props.livePrice}`,
            tip: 'The MARK token price, volume weight averaged across exchanges, updated every 15 minutes. ',
            toggle: ''
        },


        {
            color: "##2a2e35",
            title: 'SDR Peg',
            subTitle: null,
            button: <CardButton graphid={"sdr"} />,
            message: `$${props.targetPeg}`,
            tip: 'The latest price target for the MARK Token based on the global SDR basket as of the latest Quarter close, adjusted for inflation. The SDR consists of 5 different currencies which are: US Dollar (USD), Euro (EUR), Chinese Yuan (CNY), Japanese Yen, (JPY), Great British Pound (GBP).',
            toggle: ''
        },
        /*{
            color: "#2a2e35",
            title: 'CBOE VIX',
            subTitle: null,
            button: <CardButton graphid={"vix"}  />,
            message: (props.vix5DaySMAData === 0) ? <Loader
                type="Area"
                color="#2CF48B"
                height={50}
                width={80}
                /> 
                    : 
                <span><span style={{fontSize:13}}>5 Day SMA:</span><br/> {props.vix5DaySMAData} </span>,
            tip: 'The SMA stands for the Simple Moving Average. Every 5 Day close capturing the settlement of the VIX (Volatility Index) provides a new scaled number of volatility, which the MARK Token is correlated to. This data may be backdated by up to 24 hours due to our data providers limitations.',
            toggle: ''
        },*/
        // {
        //     color: "##2a2e35",
        //     title: 'CPI Oracle',
        //     subTitle: null,
        //     button: <CardButton state={box5State} boxToggle={updateBox5}/>,
        //     message: '1,000',
        //     tip: 'CPI stands for Consumer Price Index. This information is provided by the U.S. Bureau of Economic Analysis (BEA). Provider values are an average shown in the chart.',
        //     toggle: ''
        // },
        {
            color: "##2a2e35",
            title: 'Market Cap',
            subTitle: null,
            button: <CardButton graphid={"marketcap"} />,
            message: (props.currentMarketCap === 0) ? <Loader
                type="Area"
                color="#2CF48B"
                height={50}
                width={80}
            /> 
                : 
                <><span>${props.currentMarketCap}m /</span><br/> <span>${props.currentFDVMarketCap}m</span></>,

            tip: 'The market cap is calculated using the price of MARK multiplied against the circulating supply of MARK tokens.',
            toggle: ''
        },
        // {
        //     color: "##2a2e35",
        //     title: 'Asset Performance',
        //     subTitle: null,
        //     button: <CardButton state={box7State} boxToggle={updateBox7}/>,
        //     message: '1,000',
        //     tip: 'Asset Performance compares the prices of the MARK Token with other cryptocurrencies.',
        //     toggle: ''
        // },
        {
            color: "#2a2e35",
            title: 'Supply',
            subTitle: null,
            button: <CardButton graphid={"supply"} />,
            message: (props.circulatingSupply === 0) ? <Loader
                type="Area"
                color="#2CF48B"
                height={50}
                width={80}
                />
                : 
                <><span>{((Math.abs(props.circulatingSupply)/1000000000000000).toFixed(2)) }m /</span><br/> <span>{((Math.abs(props.currentSupply)/1000000000000000).toFixed(2)) }m MARK</span></>,
            tip: 'Tokenomics of Benchmark Protocol representing the circulating and total supply.',
            toggle: ''
        },



    ]

    const theme = {
        global: {
            font: {
                family: 'Poppins',
            },
        },
        card: {
            body: {
                background: {
                    color: '#2a2e35'
                }
            }
        }
    };

    const Identifier = ({ title, subTitle, size, button, message, tip, margin }) => (
        <Box flex alignContent='between' >
            <Box direction='row' flex justify='between' align='center'>
                <Box >
                    <Text size='14px' weight='bold' >{title}</Text>
                    <Text size='9.5px' weight='bold' >{subTitle}</Text>
                </Box>
                <Box>
                    <Tip
                        plain
                        content={
                            <Box 
                                background={{ color: 'black'}} 
                                round={{ size: 'xsmall'}} 
                                pad="small" 
                                gap="small" 
                                width={{ max: 'medium' }}
                            >
                                <Text size='small' >{tip}</Text>
                            </Box>
                        }   
                        dropProps={{}} 
                    >
                        <Button icon={<IoMdInformationCircle/>} />
                    </Tip>
                </Box>
            </Box>
            <Box style={{marginTop:20}} flex direction='row' justify='between' align='end' pad='small'>
                <Box >
                    {button}
                </Box>
                <Box >
                    <Text textAlign='end'>{message}</Text>
                </Box>
            </Box>
        </Box>
    )

    return (
        <Grommet theme={theme} >
            
            {(() => {
                if (props.size === 'small') {
                    return (
                        <Box pad='large'>
                            <Grid gap='medium' rows='xsmall' columns={{ count: 1, size: '280px' }} style={{ alignSelf: 'center' }}>
                                {data.map(value => (
                                    <Card key={value.title} height='100px' className="info-card">
                                        <CardBody pad='small'>
                                            <Identifier
                                                title={value.title}
                                                subTitle={value.subTitle}
                                                button={value.button}
                                                message={value.message}
                                                size='medium'
                                                tip={value.tip}
                                            />
                                        </CardBody>
                                    </Card>
                                ))}
                            </Grid>
                        </Box>
                    )
                } else if (props.size === 'medium') {
                    return (
                        <Box pad='large'>
                            <Grid gap='medium' rows='xsmall' columns={{ count: 2, size: '280px' }} style={{ alignSelf: 'center' }}>
                                {data.map(value => (
                                    <Card key={value.title} height='100px' className="info-card">
                                        <CardBody pad='small'>
                                            <Identifier
                                                title={value.title}
                                                subTitle={value.subTitle}
                                                button={value.button}
                                                message={value.message}
                                                size='small'
                                                tip={value.tip}
                                            />
                                        </CardBody>
                                    </Card>
                                ))}
                            </Grid>
                        </Box>
                    )
                } else {
                    return (
                        <Box pad='large'>
                            <Grid gap='medium' rows='xsmall' columns={{ count: 3, size: '280px' }} style={{ alignSelf: 'center' }}>
                                {data.map(value => (
                                    <Card key={value.title} height='100px' className="info-card">
                                        <CardBody pad='small'>
                                            <Identifier
                                                title={value.title}
                                                subTitle={value.subTitle}
                                                button={value.button}
                                                message={value.message}
                                                size='small'
                                                tip={value.tip}
                                            />
                                        </CardBody>
                                    </Card>
                                ))}
                            </Grid>
                        </Box>
                    )
                }
            })()}

                
        </Grommet>
    )
}

export default Cards;