import React, { useState } from 'react';
import {
    Box,
    Button,
    Collapsible,
    Heading,
    Grommet,
    Layer,
    Footer,
    ResponsiveContext,
    Text,
    Anchor,
    Main,
    Paragraph,
    Menu,
    Image,
} from 'grommet';
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     withRouter
// } from "react-router-dom";
import { Menu as MenuIcon, FormClose } from 'grommet-icons';
import PropTypes from "prop-types";
import '../css/Style.css';
import hamburger from '../Assets/hamburger_icons-02.png'
import benchmarkLogo from '../Assets/logo.png';
import markLogo from'../Assets/mark-black-sm.png';
import smGreenMark from '../Assets/mark-green-sm.png';



const AppBar = (props) => (
    <Box
        tag='header'
        direction='row'
        align='center'
        justify='between'
        background='brand'
        pad={{ left: 'medium', right: 'small', vertical: 'small' }}
        elevation='medium'
        style={{ zIndex: '1' }}
        {...props}
    />
);




const NavConnect = (props) => {






        return (
            (props.size !== 'small' && props.size !== 'medsmall') ? (
                <Box className='header-container' id='nav-bar'>
                    <AppBar direction='row' justify='around' background='black' className="app-bar"  >

                        <a href="https://benchmarkprotocol.finance/">
                            <Image fill={false} className="nav-logo" src={benchmarkLogo} alt="the Benchmark Protocol Logo" />
                        </a>


                        <nav  style={{display:'flex', alignItems:'center', flexWrap:'wrap', justifyContent:'center'}}>

                            <a className="menu-item" href="https://thepress.benchmarkprotocol.finance" target="_blank">The Press</a>


                            {/* <a className="menu-item" href="https://launchpad.benchmarkprotocol.finance" target="_blank">Launchpad</a> */}

                            <a smooth className="menu-item" href="https://benchmarkprotocol.finance/#impact" >Impact</a>


                           
                            <a smooth className="menu-item" href="https://benchmarkprotocol.finance/why-benchmark">About</a>
                            

                            <a smooth className="menu-item" href="https://benchmarkprotocol.finance/why-benchmark#roadmap">Roadmap</a>

                            <a smooth className="menu-item" href="https://benchmarkprotocol.finance/why-benchmark#roadmap">Team</a>

                            <a className="menu-item" href="https://benchmarkprotocol.finance/faq">FAQ</a>
                            

                            <Menu
                                dropBackground="black"
                                className="menu-item" 
								label=<span>Buy</span>
								items={[
									{ label: 'Uniswap (USDC)', href: "https://app.uniswap.org/#/swap?inputCurrency=0x67c597624b17b16fb77959217360b7cd18284253&outputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48" },
									{ label: 'Uniswap (ETH)', href:"https://app.uniswap.org/#/swap?inputCurrency=0x67c597624b17b16fb77959217360b7cd18284253&outputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2" },
								]}
							/>

                            <Menu
                                dropBackground="black"
                                className="menu-item"
                                label="More"
                                items={[
                                    { label: 'White Paper', href: "https://drive.google.com/file/d/1xRhdRLnGP9SQazZ9PHAkrnAeHR45wQtE/view?usp=sharing" },
                                    { label: 'Lite Paper', href: "https://drive.google.com/file/d/10P-K0RuW8s4B6bspXHwywLc-l1zneat_/view?usp=sharing" },
                                    { label: 'Overview', href: "https://drive.google.com/file/d/1mrklXkLCEdWCm41SU9REesjP5UbFty_D/view?usp=sharing" },
                                    { label: 'Blog', href: "https://benchmarkprotocol.medium.com/" },
                                    { label: 'Contact', href: "https://benchmarkprotocol.finance/contact" },
                                    { label: 'Careers', href: "https://angel.co/company/benchmark-protocol/jobs" },
                                ]}
                            />


                        </nav>

                        <Box margin={{right:'small'}}>
                            <a href="https://thepress.benchmarkprotocol.finance/" target='_blank' style={{ margin: '0 auto' }} >
                                <button className='launchpad-button launchpad-button-small'  >
                                    <Text style={{ fontSize: '16px' }}>Farm MARK </Text>
                                    
                                </button>
                            </a>

                        </Box>

                    </AppBar>

                </Box>
            ) : (

                <AppBar background='black' style={{ borderBottom: '.5px solid #333333' }}  >
                        <a href="https://benchmarkprotocol.finance/">
                            <Image fill={false} className="nav-logo-mobile" src={benchmarkLogo} alt="the Benchmark Protocol Logo" />
                        </a>

                        <Button
                            icon={<img src={hamburger} class="hamburger-img" alt="an icon for a hamburger menu" style={{width: '50px'}} />}
                            onClick={props.openSidebar}
                        />
                    </AppBar>
                )
        );
}

export default NavConnect;